import React from 'react';
import { Parallax } from 'react-parallax';
import Quote from "../quote/Quote";
import bg from '../../../img/1.jpeg';

const BrandName = (() => {

  return (
    <Parallax
      blur={{ min: -35, max: 35 }}
      bgImage={bg}
      bgImageAlt="JohnyDev"
      strength={-50}
      renderLayer={percentage => (
        <div
          className="brand-name"
          style={{
            transform: `translateY(${percentage * 20}px)`
          }}>
          <div className="sign">
            <span className="fast-flicker">J</span>
            oh
            <span className="slow-flicker">n</span>
            y
            <span className="flicker">D</span>
            ev
          </div>
          <Quote text="“Talk is cheap. Show me the code.”"/>
        </div>
      )}
    >
    </Parallax>
  );
});

export default BrandName;
