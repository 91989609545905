import React from 'react';
import './App.css';
import BrandName from "./components/atoms/brand-name/BrandName";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons';


function App() {
  return (
    <>
      <BrandName/>
      <div className="footer">
        <p>
          Find me on:
          <a href="https://github.com/rafirr" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faGithub} />
          </a>
        </p>
      </div>
    </>
  );
}

export default App;
