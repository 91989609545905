import React, { useEffect } from 'react';

const Quote = (({text}) => {

  /**
   *
   transition: all 1.7s ease-in 0.19s;
   */

  /**
   *  <span data-duration="1.1" data-delay=".23" data-blur="6">“Two&nbsp;</span>
   <span data-duration="1.4" data-delay=".43" data-blur="3">things&nbsp;</span>
   <span data-duration="1.8" data-delay=".42" data-blur="5">are&nbsp;</span>
   <span data-duration="1.2" data-delay=".25" data-blur="9">infinite:&nbsp;</span>
   <span data-duration="1.7" data-delay=".30" data-blur="4">the&nbsp;</span>
   <span data-duration="1.2" data-delay=".29" data-blur="3">universe&nbsp;</span>
   <span data-duration="1.4" data-delay=".26" data-blur="5">and&nbsp;</span>
   <span data-duration="1.7" data-delay=".19" data-blur="9">human&nbsp;</span>
   <span data-duration="1.2" data-delay=".11" data-blur="5">stupidity;&nbsp;</span>
   <span data-duration="1.9" data-delay=".19" data-blur="2" >and&nbsp;</span>
   <span data-duration="1.2" data-delay=".36" data-blur="9" >I'm&nbsp;</span>
   <span data-duration="1.8" data-delay=".17" data-blur="3" >not&nbsp;</span>
   <span data-duration="1.2" data-delay=".30" data-blur="10" >sure&nbsp;</span>
   <span data-duration="1.5" data-delay=".50" data-blur="4" >about&nbsp;</span>
   <span data-duration="1.8" data-delay=".19" data-blur="2" >the&nbsp;</span>
   <span data-duration="1.3" data-delay=".18" data-blur="4" >universe.”&nbsp;</span>
   */

  useEffect(() => {
    const timer = setTimeout(() => document.querySelector('.quote').classList.add('animated'), 1500);
    return () => clearTimeout(timer);
  }, []);

  function random(min, max) {
    return Math.floor(Math.random() * (+max + 1 - +min)) + +min;
  }

  function randomFloat(min, max, digits) {
    return (Math.random() * (min - max) + max).toFixed(digits);
  }

  function generateStyle() {
    return {
      filter: `blur(${random(2, 10)}px)`,
      transition: `all ${randomFloat(1.1, 1.8, 1)}s ease-in ${randomFloat(0.11, 0.43, 2)}s`
    }
  }

  return (
    <div className="quote">
      {text.split(' ').map(item => <span style={generateStyle()}>
          {item}
          &nbsp;
        </span>)}
    </div>
  );
});

export default Quote;
